.TabSwitch{
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
    overflow: hidden;
    text-align: center;
    background-color:  var(--lite-color);
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid var(--border-color);
}

.TabSwitch .Tab-active{
   border-radius: 8px;
   padding: 10px 0px;
   background-color: var(--primary-color);
   color: #fff;
   transition: ease-in all 0.15s;
   
}

.TabSwitch .Tab-inactive{
   border-radius: 8px;
   padding: 10px 0;
   transition: ease-in all 0.15s;

}
